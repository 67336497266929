var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-0 m-0 background-banner",attrs:{"lg":"8"}}),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"div"}},[_c('b-link',[_c('b-img',{staticStyle:{"width":"200px"},attrs:{"src":_vm.logoUrl,"alt":"Self Reliance"}})],1)],1),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Log in op uw account en begin het avontuur ")]),_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"username"}},[_c('validation-provider',{attrs:{"name":"username","vid":"username","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false:null,"name":"username","placeholder":"abc@self-reliance.sr"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Wachtwoord","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"password","placeholder":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('transition',{attrs:{"name":"fade"}},[_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTfa),expression:"showTfa"}],attrs:{"label":"Two-Factor Authenticatie Code","label-for":"tfa"}},[_c('validation-provider',{attrs:{"name":"tfa","vid":"tfa","rules":"digits:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tfa","state":errors.length > 0 ? false:null,"type":"number","name":"tfa","placeholder":"123 456"},model:{value:(_vm.tfa),callback:function ($$v) {_vm.tfa=$$v},expression:"tfa"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],staticClass:"mb-1",attrs:{"variant":"danger","show":_vm.showLoginError}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_vm._v(" Vul een valide gebruikersnaam, wachtwoord en eventuele TFA code in. ")],1)]),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" Inloggen ")])],1)]}}])}),_c('b-card-text',{staticClass:"mb-2 mt-5 text-muted font-small-1"},[_c('b',[_vm._v("Frontend version:")]),_vm._v(" "+_vm._s(_vm.frontendEnv)+" - "+_vm._s(_vm.frontendVersion)),_c('br'),_c('b',[_vm._v("API version:")]),_vm._v(" "+_vm._s(_vm.backendEnv)+" - "+_vm._s(_vm.backendVersion)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }