<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-0 m-0 background-banner"
      />
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >

          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="div"
          >
            <b-link>
              <b-img
                :src="logoUrl"
                alt="Self Reliance"
                style="width:200px"
              />
            </b-link>
          </b-card-title>
          <b-card-text class="mb-2">
            Log in op uw account en begin het avontuur
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  vid="username"
                  rules="required|email"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="username"
                    placeholder="abc@self-reliance.sr"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group
                label="Wachtwoord"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder=""
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <transition name="fade">
                <b-form-group
                  v-show="showTfa"
                  label="Two-Factor Authenticatie Code"
                  label-for="tfa"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tfa"
                    vid="tfa"
                    rules="digits:6"
                  >
                    <b-form-input
                      id="tfa"
                      v-model="tfa"
                      :state="errors.length > 0 ? false:null"
                      type="number"
                      name="tfa"
                      placeholder="123 456"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </transition>

              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showLoginError"
                class="mb-1"
              >
                <div class="alert-body">
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-50"
                  />
                  Vul een valide gebruikersnaam, wachtwoord en eventuele TFA code in.
                </div>
              </b-alert>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Inloggen
              </b-button>
            </b-form>
          </validation-observer>
          <b-card-text class="mb-2 mt-5 text-muted font-small-1">
            <b>Frontend version:</b> {{ frontendEnv }} - {{ frontendVersion }}<br>
            <b>API version:</b> {{ backendEnv }} - {{ backendVersion }}
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  BAlert,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      tfa: '',
      password: '',
      username: '',
      logoImg: require('@/assets/images/logo/self-reliance-logo.png'),
      required,
      email,
      showLoginError: false,
      showTfa: false,
      backendEnv: 'Unknown',
      backendVersion: 'Unknown',
      frontendEnv: process.env.VUE_APP_AWS_BRANCH,
      frontendVersion: process.env.VUE_APP_AWS_COMMIT_ID,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    logoUrl() {
      return this.logoImg
    },
  },
  mounted() {
    axios
      .get(`${axiosDefaultConfig.backendEndPoint}version`)
      .then(response => {
        this.backendEnv = response.data.env
        this.backendVersion = response.data.version
      })
  },
  methods: {
    login() {
      this.showLoginError = false

      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            username: this.username,
            password: this.password,
            tfa: this.tfa,
          })
            .then(response => {
              if (response.data.tfaRequired) {
                this.showTfa = true
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Two-Factor Authentication vereist',
                    icon: 'AlertTriangleIcon',
                    variant: 'warning',
                    text: 'Vul uw Two-Factor Authentication code uit de app in om verder te gaan',
                  },
                })
              } else {
                useJwt.setToken(response.data.accessToken)
                useJwt.setRefreshToken(response.data.refreshToken)

                if (response.data.requirePasswordReset) {
                  this.$router.push({ name: 'auth-reset-password' })
                } else {
                  const { userData } = response.data
                  localStorage.setItem('userData', JSON.stringify(userData))
                  this.$ability.update(userData.ability)

                  // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                  this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Welcome ${userData.fullName || userData.username}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: 'U bent succesvol ingelogd! Welkom op het Self Reliance Portaal!',
                        },
                      })
                    })
                }
              }
            })
            .catch(() => {
              this.showLoginError = true
              // this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<img src="../../../assets/images/pages/background-login.gif" height="1589" width="1820"/>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.background-banner{
  background: url("../../../assets/images/pages/background-login.png");
  background-size: cover;
  background-size: cover;
}
</style>
