import { render, staticRenderFns } from "./Login.vue?vue&type=template&id=805907ee&"
import script from "./Login.vue?vue&type=script&lang=js&"
export * from "./Login.vue?vue&type=script&lang=js&"
import style0 from "./Login.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../../../assets/images/pages/background-login.gif?vue&type=custom&index=0&blockType=img&issuerPath=%2Fbuilds%2Fcodiq%2Fself-reliance%2Fself-reliance-web%2Fsrc%2Fviews%2Fpages%2Fauthentication%2FLogin.vue&height=1589&width=1820"
if (typeof block0 === 'function') block0(component)

export default component.exports